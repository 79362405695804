html {
  background-color: rgb(138, 135, 135);
}

/* Computer */
@media only screen and (min-width: 451px) {
  p {
    font-family: "Crimson Text", serif;
    font-size: 30px;
    margin: 0%;
  }
}

/* Mobile Device */
@media only screen and (max-width: 450px) {
  p {
    font-family: "Crimson Text", serif;
    font-size: 18px;
    margin: 0%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* Computer */
@media only screen and (min-width: 451px) {
  div.main {
    padding-top: 100px;
    padding-bottom: 25px;
    background-color: rgb(138, 135, 135);
    font-family: "Contrail One", cursive;
    text-align: center;
  }
}

/* Mobile Device */
@media only screen and (max-width: 450px) {
  div.main {
    padding-top: 70px;
    padding-bottom: 25px;
    background-color: rgb(138, 135, 135);
    font-family: "Contrail One", cursive;
    text-align: center;
  }
}

/* Computer */
@media only screen and (min-width: 451px) {
  body.main {
    padding-left: 50px;
    padding-right: 50px;
    height: 100%;
    background-color: rgb(138, 135, 135);
  }
}

/* Mobile Device */
@media only screen and (max-width: 450px) {
  body.main {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    background-color: rgb(138, 135, 135);
  }
}

/* Mobile Device */
@media only screen and (max-width: 450px) {
  .MainPage {
    font-size: xx-large;
    font-family: "Contrail One", cursive;
    text-align: center;
  }
}

/* Computer */
@media only screen and (min-width: 451px) {
  .MainPage {
    font-size: 50px;
    padding-bottom: 10px;
    font-family: "Contrail One", cursive;
    text-align: center;
  }
}

/* Mobile Device */
@media only screen and (max-width: 450px) {
  h2 {
    font-size: x-large;
    text-align: center;
    font-family: "Contrail One", cursive;
    text-align: center;
    margin: 0%;
  }
}

/* Computer */
@media only screen and (min-width: 451px) {
  h2 {
    font-size: 40px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Contrail One", cursive;
    text-align: center;
    margin: 0%;
  }
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

hr.main {
  border: 2px solid rgb(29, 28, 28);
}
